.button {
  background: #00ffaa;
  border: 2px solid #00ffaa;
  border-radius: 25px;
  height: 2.95em;
  display: inline-flex;
  width: 11.2em;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.55em;
  font-weight: 600;

  font-family: sofia-pro;
  padding-right: 1.4em;
  box-shadow: 0px 5px 24px 0px rgba(0, 255, 181, 0.28);
  transition: background .3s ease, box-shadow .3s ease;

  white-space: nowrap;
  font-size: 1.1em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  line-height: unset !important;

  &:hover {
    box-shadow: none;
    background: transparent;
    img {
      transform: translateX(-10px);
    }
  }

  img {
    height: 1.2em;
    //height: 1.1em;
    width: auto;
    display: block;
    transition: all .3s ease;
    position: relative;
    top: 1px;
  }

  &--empty{
    background: #fff;
    box-shadow: unset;

    &:hover {
      background: #00ffaa;
    }
  }
}
