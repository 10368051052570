@import './global/fonts';

@import './global/variables';
@import './global/normalize/sass/normalize/import-now';
@import './global/button';
@import './global/section_lead';


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

body, html {
  overflow-x: hidden;
}

body {
  color: $base-color;
  font-weight: 300;
}

.container {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;

  @media(max-width: 1500px) and (min-width: 1230px) {
    max-width: 1200px !important;
  }

  @media(max-width: 1230px) and (min-width: 1060px){
    max-width: 1030px !important;
  }

  @media(max-width: 1060px) {
    max-width: 800px !important;
  }

  @media(max-width: 1024px) {
    max-width: 960px !important;
  }
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}


html {
  @media(max-width: 1500px) and (min-width: 1301px) {
    font-size: 90%;
  }
  @media(max-width: 1300px) and (min-width: 1201px) {
    font-size: 85%;
  }
  @media(max-width: 1200px) and (min-width: 1024px) {
    font-size: 80%;
  }
  @media(max-width: 1024px) and (min-width: 768px) {
    font-size: 80%;
  }
}
