// @import url("//hello.myfonts.net/count/39b2ab");

@font-face {
    font-family: Gordita-Bold;
    src: url(/assets/fonts/39B2AB_0_0.eot);
    src: url(/assets/fonts/39B2AB_0_0.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/39B2AB_0_0.woff2) format("woff2"), url(/assets/fonts/39B2AB_0_0.woff) format("woff"), url(/assets/fonts/39B2AB_0_0.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gordita-Medium;
    src: url(/assets/fonts/39B2AB_1_0.eot);
    src: url(/assets/fonts/39B2AB_1_0.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/39B2AB_1_0.woff2) format("woff2"), url(/assets/fonts/39B2AB_1_0.woff) format("woff"), url(/assets/fonts/39B2AB_1_0.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gordita-Regular;
    src: url(/assets/fonts/39B2AB_2_0.eot);
    src: url(/assets/fonts/39B2AB_2_0.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/39B2AB_2_0.woff2) format("woff2"), url(/assets/fonts/39B2AB_2_0.woff) format("woff"), url(/assets/fonts/39B2AB_2_0.ttf) format("truetype");
    font-display: swap;
}