/* You can add global styles to this file, and also import other style files */
@import './global/global';
@import './global/normalize/sass/normalize';
@import './global/variables';

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body.menu-open,
html.menu-open {
  overflow: hidden !important;
}

.ps__rail-y,
.ps__rail-x {
  display: none !important;
}

body.body--fixed {
  position: fixed;
  right: 0;
  left: 0;
}

body {
  .header-menu {
    display: flex !important;
  }
}

.loading {
  display: none;
}

.not-ready {
  .loading {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      text-align: center;
      margin: 0 auto;
      padding-top: 7em;
    }
  }
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 250px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0fa;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0.0)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.header {
  padding-left: 5.088em;
  padding-right: 5.088em;
  position: relative;
  margin-bottom: -2.4em;
  z-index: 1001;


  &__phone {
    @media(min-width: 1024px) {
      display: none;
    }
    margin-left: auto;
    margin-right: 0;
    margin-top: 0.3em;
    margin-right: 1.1em;
  }

  @media(max-width: 1500px) and (min-width: 1400px) {
    padding-left: 3em;
    padding-right: 3em;
  }
  @media(max-width: 1400px) and (min-width: 1200px) {
    padding-left: 2.088em;
    padding-right: 2.088em;
  }

  @media(max-width: 1200px) and (min-width: 1025px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media(max-width: 1024px) {
    padding: 15px;
    justify-content: space-between;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  will-change: transform;
  background: #fff;
  height: 6em;

  &__toggle {

    @media(min-width: 1025px) {
      display: none;
    }
  }

  &__contents {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6em;

    @media(max-width: 1024px) {
      height: 4.3em;
    }
  }

  //transition: transform .3s linear;

  &--sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    height: 5em;

    @media(max-width: 1024px) {
      display: flex;
      padding-top: 0.5em;
      align-items: center;
      height: auto;
      padding-bottom: 0.5em;
    }

    .header__contents {
      height: 5em;

      @media(max-width: 1024px) {
        height: 3.4em;
      }
    }

    padding-top: 0;
    box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.05);
    transform: none;
    transition: transform .3s linear;

    .header__logo__image {
      width: 12em;
    }

    .button {
      font-size: 0.9em;
    }
  }

  &--hidden {
    transform: translateY(-100%);
    transition: transform .3s linear;
  }

  &__offer-button {
    @media(min-width: 1025px) {
      display: none;
    }

    width: 20px;

    &__link {
      &__image {
        width: 20px;
        display: block;
      }
    }
  }

  &__mobile-menu {
    @media(min-width: 1025px) {
      display: none;
    }

  }

  &__logo {
    a {
      display: block;
      max-width: 208px;
    }
  }

  &__button {
    .button {
      width: 13.4em;

      @media (max-width: 1800px) and (min-width: 1025px) {
        font-size: 0.8em;
      }

      @media(max-width: 1100px) {
        width: auto;

        img {
          display: none;
        }
      }
    }

    @media(max-width: 1024px) {
      display: none;
    }
  }

  &__menu {

    //width: 52.7%;
    //
    //@media(max-width: 1600px) and (min-width: 1331px){
    //  width: 61.7%;
    //}
    //
    //@media(max-width: 1330px) and (min-width: 951px) {
    //  width: 58.7%;
    //}
    //@media(max-width: 950px) and (min-width: 1025px) {
    //  width: 70.7%;
    //font-size: 90%;
    //}

    @media(max-width: 1024px) {
      display: none;
    }
  }
}

.header-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3.5em;
  font-family: sofia-pro;

  @media(max-width: 900px) {
    font-size: 0.9em;
  }

  &__item {
    cursor: pointer;
    margin: 0 1.5em;

    @media(max-width: 1620px) {
      margin: 0 1em;
    }

    @media(max-width: 1100px) {
      margin: 0 0.5em;
    }
    
    @media (max-width: 1800px) and (min-width: 1501px) {
      font-size: 90%;
    }
  }

  &__sub {
    position: absolute;
    background: #fff;
    top: 100%;
    left: 5.088em;
    right: 5.088em;
    z-index: 10;
    box-shadow: 0px 59px 114px 0px rgba(52, 52, 52, 0.35);
    padding-top: 5.7em;
    padding-bottom: 4.8em;
    display: none;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 4em;
    padding-right: 4em;

    &--active {
      display: block;
    }

    .decoration-holder {
      position: absolute;
      pointer-events: none;
      overflow: hidden;
      user-select: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__image0 {
      position: absolute;
      right: -5.1em;
      top: 4.6em;
    }

    &__decoration0 {
      position: absolute;
      width: 7.5em;
      height: 7.5em;
      border: 14px solid $primary-green;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      bottom: -2.7em;
      left: -2.95em;
      z-index: 1;
    }

    &__decoration {
      position: absolute;
      font-family: Gordita-Bold;
      opacity: .07;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #000;
      -webkit-text-fill-color: transparent;
      margin-top: .1em;
      right: -0.1em;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 7.375em;
      bottom: 0.45em;
    }

    &__title {
      font-weight: 500;
      color: #00e8b5;
      font-size: normalize-rhythm(20px);
      margin-bottom: 3.8em;
    }

    @media(max-width: 1024px) and (min-width: 1025px) {
      left: 2.5em;
      right: 2.5em;
    }

    &__items {
      display: flex;
      justify-content: space-between;

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 1.87em;
        font-size: 1.1em;

        &:hover {
          color: $primary-green;

          &:before {
            color: $primary-green;
          }
        }

        &:before {
          content: attr(data-counter);
          font-family: sofia-pro;
          font-weight: 500;
          color: #cdcdcd;
          font-size: normalize-rhythm(11px);
          margin-right: 1.8em;
        }

        &__link {
          font-size: normalize-rhythm(22px);
          font-family: sofia-pro;
          font-weight: 500;

          @media(max-width: 1450px) {
            font-size: 1.28em;
          }

        }
      }
    }
  }

  &__link {

    position: relative;
    padding-bottom: 3.3em;
    display: block;
    font-size: 1.1em;

    &__icon {
      width: 12px;
      display: inline-block;
      position: relative;
      top: 2px;

      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;

      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
    }

    &--children {
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 9px 9px;
        border-color: transparent transparent #fff transparent;
        top: 100%;
        margin-top: -32px;
        left: 50%;
        margin-left: -5px;
        display: none;
        z-index: 11;
        cursor: default;
      }
    }

    &--active:before {
      display: block !important;
    }

    &--sticky {
      &:before {
        margin-top: -32px;
      }
    }

    &:hover {
      img {
        transform: rotate(-90deg);
      }

    }

    &--active {
      img {
        transform: rotate(-180deg) !important;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background: $primary-green;
      transition: all .3s ease;
      margin-top: -2.95em;
    }

    &:hover:after {
      width: 100%;
    }

  }

  &__close {
    position: absolute;
    border: 1px solid #000;
    background: #fff;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    right: 1.05em;
    top: 1.25em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;

    @media(max-width: 1024px) {
      top: 15px;
      right: 15px;
      width: 2em;
      height: 2em;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: #00ffa7;
    }

    img {
      max-width: 50%;
      display: block;
      transition: all .3s ease;

      @media(max-width: 1024px) {
        max-width: unset;
        width: 7px;
        height: 8px;
      }
    }
  }

}