@import './global/normalize/sass/normalize';

.section-lead {
  &__text {
    font-weight: 300;
    font-size: normalize-rhythm(20px);
    margin: 0;
    text-align: center;
    color: #6D6D6D;
    line-height: normalize-rhythm(33px, 20px);
    max-width: 41em;
    margin: 0 auto;
    @media(max-width: 767px) {
      font-size: 1em;
    }
  }

  &__title {
    font-weight: unset;
    font-size: normalize-rhythm(50px);
    margin: 0;
    line-height: 1.2;
    text-align: center;
    font-family: Gordita-Bold;
    margin-bottom: 0.72em;
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
    line-height: normalize-rhythm(67px, 50px);

    @media(max-width: 1500px) {
      font-size: 2.7em;
    }

    @media(max-width: 767px) {
      font-size: 2em;
    }
  }
}
