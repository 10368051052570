$indent-amount: 0px;
$base-font-family: Gordita-Regular;
$base-color: #000000;
$form-invalid-color: red;
$normalize-vertical-rhythm : true;
$base-font-size: 16px;
$primary-green: #00ffaa;

$img-path : 'https://cdn2.alphta.de/assets/';
$font-path : '/assets/fonts';
